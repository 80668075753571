import React, { useState, useCallback, useMemo, useRef } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import CustomInfoWindow from './CustomInfoWindow';
import '../styles/Map.css';

const Map = React.memo(({ events, center }) => {
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [locationEvents, setLocationEvents] = useState([]);
    const mapRef = useRef(null);

    const mapStyles = useMemo(() => ({
        height: "100vh",
        width: "100%"
    }), []);

    const handleMarkerClick = useCallback((event) => {
        const filteredEvents = events.filter(e => 
            e['Latitude'] === event['Latitude'] && e['Longitude'] === event['Longitude']
        );
        setLocationEvents(filteredEvents);
        setSelectedEvent(filteredEvents[0]);
    }, [events]);

    const handleMapClick = useCallback(() => {
        setSelectedEvent(null);
        setLocationEvents([]);
    }, []);

    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);

    const memoizedMarkers = useMemo(() => events.map((event, index) => (
        <Marker
            key={`${event['Latitude']}-${event['Longitude']}-${index}`}
            position={{ lat: event['Latitude'], lng: event['Longitude'] }}
            onClick={() => handleMarkerClick(event)}
        />
    )), [events, handleMarkerClick]);

    return (
        <div className="map-container">
            <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={12}
                    center={center}
                    options={{ disableDefaultUI: true, gestureHandling: 'cooperative' }}
                    onClick={handleMapClick}
                    onLoad={onMapLoad}
                >
                    {memoizedMarkers}

                    {selectedEvent && (
                        <CustomInfoWindow
                            event={selectedEvent}
                            position={{ lat: selectedEvent['Latitude'], lng: selectedEvent['Longitude'] }}
                            onCloseClick={() => setSelectedEvent(null)}
                            locationEvents={locationEvents}
                            setSelectedEvent={setSelectedEvent}
                        />
                    )}
                </GoogleMap>
            </LoadScript>
        </div>
    );
});

export default Map;