import './App.css';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Filter from './components/Filter';
import Map from './components/Map';
import WelcomeOverlay from './components/WelcomeOverlay';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_BASE_URL
  : window.location.hostname === 'localhost'
    ? 'https://localhost:5000'
    : `https://${window.location.hostname}:5000`;

console.log('API_BASE_URL:', API_BASE_URL);

const DEFAULT_CENTER = { lat: 43.6532, lng: -79.3832 }; // Default to Toronto

const App = () => {
    const [filters, setFilters] = useState({
        date: '',
        type: '',
        age: '',
        city: ''
    });
    const [events, setEvents] = useState([]);
    const [userLocation, setUserLocation] = useState(DEFAULT_CENTER);
    const [showWelcome, setShowWelcome] = useState(true);

    const handleFilterChange = useCallback((newFilters) => {
        setFilters(newFilters);
    }, []);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/events`, { params: filters });

                if (typeof response.data === 'string') {
                    try {
                        const parsedData = JSON.parse(response.data);
                        console.log("Parsed data:", parsedData);
                        setEvents(parsedData);
                    } catch (parseError) {
                        console.error("Error parsing response data:", parseError);
                        setEvents([]);
                    }
                } else {
                    setEvents(response.data);
                }
            } catch (error) {
                console.error("Error fetching events:", error);
                setEvents([]);
            }
        };
    
        fetchEvents();
    }, [filters]);

    useEffect(() => {
        const requestUserLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setUserLocation({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        });
                    },
                    (error) => {
                        console.error("Error getting user location:", error);
                        // Silently fall back to default location
                    }
                );
            } else {
                console.error("Geolocation is not supported by this browser.");
                // Silently fall back to default location
            }
        };

        requestUserLocation();
    }, []);

    return (
        <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            {showWelcome && <WelcomeOverlay onClose={() => setShowWelcome(false)} />}
            <Filter onFilterChange={handleFilterChange} />
            <div style={{ flexGrow: 1, overflow: 'hidden' }} className="map-container">
                <Map events={events} center={userLocation} />
            </div>
        </div>
    );
};

export default App;
