export function triggerCalendarDownload(event, calendarType) {
    let url;

    switch (calendarType) {
        case 'google':
            url = generateGoogleCalendarUrl(event);
            window.open(url, '_blank');
            break;
        case 'yahoo':
            url = generateYahooCalendarUrl(event);
            window.open(url, '_blank');
            break;
        case 'outlook':
        case 'ical':
        default:
            const icsContent = generateICSFile(event);
            downloadICSFile(icsContent, `${event['Program.Name1']}.ics`);
            break;
    }
}

function generateICSFile(event) {
    const startDate = new Date(event['ProgramSchedEvent.Start']);
    const endDate = new Date(event['ProgramSchedEvent.End']);
    
    return [
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        `SUMMARY:${event['Program.Name1']}`,
        `DTSTART:${formatDateForICS(startDate)}`,
        `DTEND:${formatDateForICS(endDate)}`,
        `DESCRIPTION:${event['ProgramSchedEvent.Description1']}`,
        `LOCATION:${event['ProviderSite.Address']}, ${event['ProviderSite.City']}`,
        'END:VEVENT',
        'END:VCALENDAR'
    ].join('\r\n');
}

function generateGoogleCalendarUrl(event) {
    const startDate = new Date(event['ProgramSchedEvent.Start']);
    const endDate = new Date(event['ProgramSchedEvent.End']);
    const details = encodeURIComponent(event['ProgramSchedEvent.Description1']);
    const location = encodeURIComponent(`${event['ProviderSite.Address']}, ${event['ProviderSite.City']}`);
    
    return `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(event['Program.Name1'])}&dates=${formatDateForGoogle(startDate)}/${formatDateForGoogle(endDate)}&details=${details}&location=${location}`;
}

function generateYahooCalendarUrl(event) {
    const startDate = new Date(event['ProgramSchedEvent.Start']);
    const endDate = new Date(event['ProgramSchedEvent.End']);
    const details = encodeURIComponent(event['ProgramSchedEvent.Description1']);
    const location = encodeURIComponent(`${event['ProviderSite.Address']}, ${event['ProviderSite.City']}`);
    
    return `https://calendar.yahoo.com/?v=60&title=${encodeURIComponent(event['Program.Name1'])}&st=${formatDateForYahoo(startDate)}&et=${formatDateForYahoo(endDate)}&desc=${details}&in_loc=${location}`;
}

function formatDateForGoogle(date) {
    return date.toISOString().replace(/-|:|\.\d+/g, '');
}

function formatDateForYahoo(date) {
    return date.toISOString().replace(/-|:|\.\d+/g, '').slice(0, -1);
}

function formatDateForICS(date) {
    return date.toISOString().replace(/-|:|\.\d+/g, '');
}

function downloadICSFile(content, filename) {
    const blob = new Blob([content], { type: 'text/calendar;charset=utf-8' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}