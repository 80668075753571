import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { triggerCalendarDownload } from '../utils/calendarUtils';
import '../styles/FloatingPanel.css';

const FloatingPanel = React.memo(({ events, onClose }) => {
    const [openDropdown, setOpenDropdown] = useState(null);
    const dropdownRef = useRef(null);

    const handleAddToCalendar = (event, calendarType) => {
        triggerCalendarDownload(event, calendarType);
        setOpenDropdown(null);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpenDropdown(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const memoizedEvents = useMemo(() => events.map((event, index) => (
        <div key={`${event['Program.Name1']}-${index}`} className="event-card">
            <h3>{event['Program.Name1']}</h3>
            <p><strong>Date:</strong> {new Date(event['ProgramSchedEvent.Start']).toLocaleDateString()}</p>
            <p><strong>Time:</strong> {new Date(event['ProgramSchedEvent.Start']).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })} - {new Date(event['ProgramSchedEvent.End']).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}</p>
            <details>
                <summary>More Details</summary>
                <p>{event['ProgramSchedEvent.Description1']}</p>
                <p><strong>Age Range:</strong> {event['ProgAgeGroup.Desc1']}</p>
                <p><strong>Location:</strong> {event['ProviderSite.Address']}, {event['ProviderSite.City']}</p>
                <p><a href={event['URL']} target="_blank" rel="noopener noreferrer">View Event Details</a></p>
                {event['ProgramSchedEvent.IsPreReg'] && <p className="pre-registration-message">* Pre-registration may be required</p>}
                <div className="calendar-dropdown" ref={dropdownRef}>
                    <span className="add-to-calendar-text" onClick={() => setOpenDropdown(index)}>📅 Add to Calendar</span>
                    {openDropdown === index && (
                        <div className="dropdown-menu">
                            <span onClick={() => handleAddToCalendar(event, 'google')}>Google Calendar</span>
                            <span onClick={() => handleAddToCalendar(event, 'yahoo')}>Yahoo Calendar</span>
                            <span onClick={() => handleAddToCalendar(event, 'outlook')}>Outlook</span>
                            <span onClick={() => handleAddToCalendar(event, 'ical')}>iCal</span>
                        </div>
                    )}
                </div>
            </details>
        </div>
    )), [events, openDropdown]);

    return (
        <div className="floating-panel">
            <div className="floating-panel-header">
                <h2>All Events at this Location</h2>
                <button onClick={onClose}>Close</button>
            </div>
            <div className="floating-panel-content">
                {memoizedEvents}
            </div>
        </div>
    );
});

FloatingPanel.propTypes = {
    events: PropTypes.arrayOf(PropTypes.shape({
        'Program.Name1': PropTypes.string.isRequired,
        'ProgramSchedEvent.Start': PropTypes.string.isRequired,
        'ProgramSchedEvent.End': PropTypes.string.isRequired,
        'ProgramSchedEvent.Description1': PropTypes.string,
        'ProgAgeGroup.Desc1': PropTypes.string,
        'ProviderSite.Address': PropTypes.string,
        'ProviderSite.City': PropTypes.string,
        'URL': PropTypes.string.isRequired,
        'ProgramSchedEvent.IsPreReg': PropTypes.bool
    })).isRequired,
    onClose: PropTypes.func.isRequired
};

export default FloatingPanel;