import React, { useState, useCallback, useRef } from 'react';
import { InfoWindow } from '@react-google-maps/api';
import { triggerCalendarDownload } from '../utils/calendarUtils';
import FloatingPanel from './FloatingPanel';
import '../styles/CustomInfoWindow.css';

const CustomInfoWindow = ({ event, position, onCloseClick, locationEvents, setSelectedEvent }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [showFloatingPanel, setShowFloatingPanel] = useState(false);
    const dropdownRef = useRef(null);

    const handleAddToCalendar = (calendarType) => {
        triggerCalendarDownload(event, calendarType);
        setIsDropdownOpen(false);
    };

    const handleNextEvent = useCallback(() => {
        if (locationEvents && locationEvents.length > 1) {
            const currentIndex = locationEvents.findIndex(e => e === event);
            const nextIndex = (currentIndex + 1) % locationEvents.length;
            setSelectedEvent(locationEvents[nextIndex]);
        }
    }, [locationEvents, event, setSelectedEvent]);

    const handlePrevEvent = useCallback(() => {
        if (locationEvents && locationEvents.length > 1) {
            const currentIndex = locationEvents.findIndex(e => e === event);
            const prevIndex = (currentIndex - 1 + locationEvents.length) % locationEvents.length;
            setSelectedEvent(locationEvents[prevIndex]);
        }
    }, [locationEvents, event, setSelectedEvent]);

    const handleViewAllEvents = useCallback(() => {
        setShowFloatingPanel(true);
    }, []);

    const handleCloseFloatingPanel = useCallback(() => {
        setShowFloatingPanel(false);
    }, []);

    return (
        <>
            <InfoWindow
                position={position}
                onCloseClick={onCloseClick}
                options={{
                    pixelOffset: new window.google.maps.Size(0, -30)
                }}
            >
                <div className="info-window-content">
                    <div className="info-window-header">
                        <h3>{event['Program.Name1']}</h3>
                        <div className="calendar-dropdown" ref={dropdownRef}>
                            <span 
                                className="add-to-calendar-text" 
                                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                            >
                                📅 Add to Calendar
                            </span>
                            {isDropdownOpen && (
                                <div className="dropdown-menu">
                                    <span onClick={() => handleAddToCalendar('google')}>Google Calendar</span>
                                    <span onClick={() => handleAddToCalendar('yahoo')}>Yahoo Calendar</span>
                                    <span onClick={() => handleAddToCalendar('outlook')}>Outlook</span>
                                    <span onClick={() => handleAddToCalendar('ical')}>iCal</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <p>{event['ProgramSchedEvent.Description1']}</p>
                    <p>Date: {new Date(event['ProgramSchedEvent.Start']).toLocaleDateString()}</p>
                    <p>Time: {new Date(event['ProgramSchedEvent.Start']).toLocaleTimeString()} - {new Date(event['ProgramSchedEvent.End']).toLocaleTimeString()}</p>
                    <p>Age Range: {event['ProgAgeGroup.Desc1']}</p>
                    <p>Location: {event['ProviderSite.Address']}, {event['ProviderSite.City']}</p>
                    <p><a href={event['URL']} target="_blank" rel="noopener noreferrer">View Event Details</a></p>
                    {event['ProgramSchedEvent.IsPreReg'] && <p className="pre-registration-message">* Pre-registration may be required</p>}
                    {locationEvents && locationEvents.length > 1 && (
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                            <button onClick={handlePrevEvent}>Previous</button>
                            <button onClick={handleNextEvent}>Next</button>
                        </div>
                    )}
                    <button onClick={handleViewAllEvents} style={{ marginTop: '10px', width: '100%' }}>View All Events at this Location</button>
                </div>
            </InfoWindow>
            {showFloatingPanel && (
                <FloatingPanel 
                    events={locationEvents} 
                    onClose={handleCloseFloatingPanel} 
                />
            )}
        </>
    );
};

export default CustomInfoWindow;