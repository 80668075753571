import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import '../styles/Filter.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://localhost:5000';

const Filter = React.memo(({ onFilterChange }) => {
    const [filters, setFilters] = useState({
        date: null,
        type: '',
        age: ''
    });

    const [ageRanges, setAgeRanges] = useState([]);
    const [dateRange, setDateRange] = useState({ minDate: null, maxDate: null });
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const [ageRangesResponse, dateRangeResponse] = await Promise.all([
                    axios.get(`${API_BASE_URL}/age-ranges`),
                    axios.get(`${API_BASE_URL}/date-range`)
                ]);
                const ageRangesData = ageRangesResponse.data;
                if (!ageRangesData.includes('All Ages')) {
                    ageRangesData.unshift('All Ages');
                }
                setAgeRanges(ageRangesData);
                setDateRange({
                    minDate: new Date(dateRangeResponse.data.minDate),
                    maxDate: new Date(dateRangeResponse.data.maxDate)
                });
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(`Failed to load data. Error: ${error.message}`);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    const handleInputChange = useCallback((name, value) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: value
        }));
    }, []);

    useEffect(() => {
        onFilterChange(filters);
    }, [filters, onFilterChange]);

    const handleShowAllDates = () => {
        handleInputChange('date', null);
    };

    const handleDateChange = (date) => {
        if (date instanceof Date && !isNaN(date)) {
            // Create a new Date object in the local timezone
            const formattedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate()).toLocaleDateString('en-CA');
            console.log('Selected date:', date);
            console.log('Formatted date sent to server:', formattedDate);
            handleInputChange('date', formattedDate);
        } else {
            handleInputChange('date', null);
        }
    };

    const handleAgeChange = (event) => {
        const selectedAge = event.target.value;
        handleInputChange('age', selectedAge === 'All Ages' ? '' : selectedAge);
    };

    if (isLoading) return <div>Loading filters...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div className="filter-container">
            <div className="filter-row">
                <label>Date:</label>
                <div className="filter-controls">
                    <button onClick={handleShowAllDates}>Show all dates</button>
                    <DatePicker
                        selected={filters.date ? new Date(filters.date + 'T00:00:00') : null}
                        onChange={handleDateChange}
                        dateFormat="yyyy-MM-dd"
                        placeholderText="Pick a date"
                        minDate={dateRange.minDate}
                        maxDate={dateRange.maxDate}
                    />
                </div>
            </div>
            <div className="filter-row">
                <label>Age:</label>
                <div className="filter-controls">
                    <select
                        value={filters.age || 'All Ages'}
                        onChange={handleAgeChange}
                        className="age-select"
                    >
                        {ageRanges.map((age, index) => (
                            <option key={index} value={age}>
                                {age}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="filter-row">
                <label>Program:</label>
                <div className="filter-controls">
                    <input
                        type="text"
                        name="type"
                        placeholder="Search Program Name"
                        value={filters.type}
                        onChange={(e) => handleInputChange('type', e.target.value)}
                    />
                </div>
            </div>
        </div>
    );
});

Filter.propTypes = {
    onFilterChange: PropTypes.func.isRequired,
};

export default Filter;